<template>
  <div class="wytg">
    <ShowDetail
      v-if="showDetail"
      @closeDialog2="closeDialog2"
      ref="showDetail"
    />
    <div class="form-list">
      <div class="table-search">
        <h3>收藏的托管土地</h3>
        <!-- 方便找页面 后期可能删除 -->
        <FormQuery
          ref="formquery"
          :data="Query"
          :key="keyForm"
          @submit="search"
          @reset="reset"
        >
        </FormQuery>
      </div>
      <div class="table-content">
        <table-page
          :data="tableData"
          :columns="columns"
          :currentPage="query.pnum"
          :pageSize="query.psize"
          :total="total"
          :border="true"
          @sizeChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        >
          <template slot="orderCode" slot-scope="scope">
            <span>{{ scope.row.orderCode ? scope.row.orderCode : "-" }}</span>
          </template>
          <template slot="createTime" slot-scope="scope">
            <span>{{
              scope.row.createTime ? scope.row.createTime.substr(0, 10) : "-"
            }}</span>
          </template>
          <template slot="orderType" slot-scope="scope">
            <span>{{
              scope.row.orderType == 1 ? "全程托管" : "环节托管"
            }}</span>
          </template>
          <template slot="orderState" slot-scope="scope">
            <span v-if="scope.row.orderState == '00'">待成交</span>
            <span v-if="scope.row.orderState == '01'">托管中</span>
            <span v-if="scope.row.orderState == '02'">托管结束</span>
            <span v-if="scope.row.orderState == null">待成交</span>
          </template>
          <template slot="Time" slot-scope="scope">
            <span
              >{{
                scope.row.startDate ? scope.row.startDate.substr(0, 10) : ""
              }}~{{
                scope.row.endDate ? scope.row.endDate.substr(0, 10) : ""
              }}</span
            >
          </template>
          <template slot="operation" slot-scope="scope">
            <el-button type="text" size="small" @click="detailClick(scope.row)"
              >查看</el-button
            >
            <el-button type="text" size="small" @click="noSelect(scope.row)"
              >取消收藏</el-button
            >
          </template>
        </table-page>
      </div>
    </div>
  </div>
</template>
      <script>
import FormQuery from "@/components/form/form-search";
import TablePage from "@/components/table/table-page";
import ShowDetail from "./showDetail";
import { sctgtdColumn } from "../tableColumn";
import { getCollectionToPage } from "@/api/projectManage";
import { removeCollection, getXzqArr } from "@/api/add";
import { removeEmptyChildren } from "@/js/utils/util";
const Query = [
  {
    name: "areaCode",
    label: "地区：",
    componentType: "cascader",
    placeholder: "请选择",
    width: "180px",
    props: {
      label: "areaName",
      children: "children",
      value: "id",
      checkStrictly: true,
    },
    dataOptions: [],
  },
  {
    name: "cropCode",
    label: "作物类型：",
    componentType: "input",
    inputType: "",
    placeholder: "",
    width: "160px",
  },
  {
    name: "orderType",
    label: "服务类型：",
    componentType: "select",
    placeholder: "请选择",
    width: "160px",
    dataOptions: [
      {
        name: "全程托管",
        value: 1,
      },
      {
        name: "环节托管",
        value: 2,
      },
    ],
  },
];
export default {
  components: {
    FormQuery,
    TablePage,
    ShowDetail,
  },
  filters: {
    name1Filter(value) {
      return value == 1 ? "全程托管" : "环节托管";
    },
  },
  data() {
    return {
      keyForm: 1, //下拉框为请求来的数据时监听会用到
      form: {},
      showDetail: false,
      Query: Query,
      query: {
        pnum: 1,
        psize: 10,
      },
      total: 0,
      tableData: [],
      columns: sctgtdColumn,
      tableData: [],
      options: [
        { value: 0, label: "已成交" },
        { value: 1, label: "未成交" },
      ],
    };
  },
  created() {
    this.getRegions();
    this.getList();
  },
  methods: {
    // 关闭详情
    closeDialog2() {
      this.showDetail = false;
    },
    // 点击详情
    detailClick(val) {
      this.showDetail = true;
      this.$nextTick(() => {
        this.$refs.showDetail.init(val);
      });
    },
    async getRegions() {
      // 获取地区信息
      let res = await getXzqArr({
        level: 3,
      });
      if (res.code == 0) {
        let hasRegion = setInterval(() => {
          this.Query[0].dataOptions = removeEmptyChildren(res.data);
          this.keyForm++;
          clearInterval(hasRegion);
        }, 100);
      }
    },
    // 取消收藏
    noSelect(obj) {
      this.$confirm("确定取消收藏?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await removeCollection({ id: obj.id });
          if (res.code == 0) {
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    // 分页点击
    handleCurrentChange(page) {
      this.query.pnum = page;
      this.getList();
    },
    // 分页数量改变
    handleSizeChange(size) {
      this.query.pnum = 1;
      this.query.psize = size;
      this.getList();
    },
    // 获取列表
    async getList() {
      let res = await getCollectionToPage(this.query);
      if (!res.code) {
        this.tableData = res.data;
        this.total = res.dataNum;
      } else {
        this.$message.error("查询失败");
      }
    },
    // 条件查询
    search(params) {
      let params2 = {
        areaCode: params.areaCode
          ? params.areaCode[params.areaCode.length - 1]
          : null,
        orderType: params.orderType,
        cropCode: params.cropCode,
        pnum: 1,
      };
      this.query = { ...this.query, ...params2 };
      this.getList();
    },
    // 条件重置
    reset() {
      this.query = {
        pnum: 1,
        psize: 10,
      };
      this.getList();
    },
  },
};
</script>
      <style lang="less" scoped>
.form-list {
  .table-search {
    padding: 0 0 0 30px;
    /deep/.el-button {
      padding: 10px 20px !important;
    }
    .tools {
      margin-bottom: 18px;
      margin-top: 6px;
    }
  }
  .table-content {
    padding: 0 30px;
    margin-top: 6px;
  }
}
</style>
